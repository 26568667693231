<template>
  <div>
    <v-card class="mb-4">
      <v-system-bar
        >Personen<v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-help </v-icon>
          </template>
          <span>Einzelnen Personen können durch Klick deaktiviert werden</span>
        </v-tooltip>
      </v-system-bar>
      <v-chip-group column>
        <PersonChip
          hideExt
          v-for="student in candidates"
          :key="student.id"
          :value="student"
          :disabled="student.disabled"
          :class="[student.active ? 'active' : '']"
          @click="student.disabled = !student.disabled"
        />
      </v-chip-group>
      <v-divider></v-divider>
      <v-card-actions
        ><v-btn
          text
          @click="prepare()"
          :disabled="
            candidates.filter((el) => !el.disabled && !el.active).length ==
            value.length
          "
          >zurücksetzen</v-btn
        ><v-spacer></v-spacer>
        <v-btn text @click="choice()" class="mr-2" color="primary"
          >zufällig wählen</v-btn
        ></v-card-actions
      >
    </v-card>

    <v-card class="mb-4">
      <v-system-bar>neue Gruppen bilden</v-system-bar>
      <v-card-actions>
        <v-switch
          inset
          v-model="sorted"
          label="sortiert"
          hide-details
          class="mt-0"
          :disabled="shuffeled.length > 0"
        ></v-switch>

        <v-spacer></v-spacer>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              tile
              text
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              color="primary"
            >
              Gruppen bilden
            </v-btn>
          </template>
          <v-list subheader>
            <v-list-item
              v-for="index in 5"
              :key="'group' + index"
              @click="group(index + 1)"
            >
              <v-list-item-title>{{ index + 1 }}er-Gruppen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              tile
              text
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              color="primary"
            >
              Klasse teilen
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="index in 5"
              :key="'divide' + index"
              @click="divide(index + 1)"
            >
              <v-list-item-title>{{ index + 1 }} Teile</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn tile text @click="shuffle()" color="primary"
          >zufällige reihenfolge</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="shuffeled.length > 0">
      <v-system-bar>Zufällige Reihenfolge</v-system-bar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            right
            absolute
            text
            @click="copyShuffle()"
            ><v-icon>mdi-content-copy</v-icon></v-btn
          >
        </template>
        <span>Zufällige Reihenfolge in Zwischenablage kopieren</span>
      </v-tooltip>
      <v-card-text>Die Personen wurden zufällig nummeriert:</v-card-text>
      <v-divider></v-divider>
      <v-list subheader>
        <transition-group
          name="flip-list"
          tag="div"
          class="mt-4 d-flex justify-start flex-wrap"
        >
          <PersonItemBasic
            v-for="(person, index) in shuffeled"
            :key="'shuffeled' + person.id"
            :value="person"
          >
            <v-chip>{{ index + 1 }}</v-chip>
          </PersonItemBasic>
        </transition-group>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer
        ><v-btn text @click="reset()" class="mr-2" :disabled="!hasAnything"
          >zurücksetzen</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="groups.length > 0" class="mb-4">
      <v-system-bar
        >erstellte Gruppen<v-spacer></v-spacer> {{ groups.length }} Gruppen
      </v-system-bar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            right
            absolute
            text
            @click="copyGroups()"
            ><v-icon>mdi-content-copy</v-icon></v-btn
          >
        </template>
        <span>Gruppeneinteilung in Zwischenablage kopieren</span>
      </v-tooltip>

      <v-card-text
        >Es wurden zufällig {{ groups.length }} Gruppen erstellt.</v-card-text
      >

      <div class="d-flex align-start flex-wrap">
        <v-card
          class="mb-4 mx-2"
          width="330"
          flat
          outlined
          v-for="(group, index) in groups"
          :key="'group' + index"
        >
          <v-system-bar>
            Gruppe {{ index + 1 }} <v-spacer />{{
              group.length
            }}
            Personen</v-system-bar
          >
          <v-list>
            <transition-group
              name="flip-list"
              tag="div"
              class="d-flex justify-start flex-wrap"
            >
              <PersonItemBasic
                v-for="(person, index) in needsSort(group)"
                :key="'group' + person.id"
                :value="person"
              >
                <v-chip>{{ index + 1 }}</v-chip>
              </PersonItemBasic>
            </transition-group>
          </v-list>
          <template
            v-if="
              index == groups.length - 1 &&
              groups[groups.length - 1].length < groupSize
            "
          >
            <v-divider></v-divider>
            <v-card-actions
              ><v-btn @click="distributeLast()" block text
                >Letzte Gruppe verteilen</v-btn
              ></v-card-actions
            >
          </template>
        </v-card>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer
        ><v-btn text @click="reset()" class="mr-2" :disabled="!hasAnything"
          >zurücksetzen</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonChip from "common/components/PersonChip.vue";

import { copyToClipboard } from "common/utils/helper.js";
import { personName, sortPeople } from "common/utils/people";

export default {
  name: "Dice",
  components: { PersonItemBasic, PersonChip },
  props: {
    name: {
      type: String,
      default: "Würfeln",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      candidates: [],
      groups: [],
      groupSize: 0,
      shuffeled: [],
      sorted: false,
    };
  },
  computed: {
    hasAnything() {
      return this.shuffeled.length > 0 || this.groups.length > 0;
    },
  },
  watch: {
    value() {
      this.prepare();
    },
  },
  methods: {
    copyToClipboard,
    personName,
    async reset() {
      if (!this.hasAnything) return;
      if (
        await this.$root.confirm({
          message: "Die bereits erstellten Gruppen gehen verloren.",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.groups = [];
        this.shuffeled = [];
      }
    },
    async prepare() {
      if (this.candidates.length == 0) {
        this.candidates = [];
        for (const person of this.value) {
          this.candidates.push({ ...person, disabled: false, active: false });
        }
      } else if (
        await this.$root.confirm({
          message:
            "Bereits gewählte (oder manuell deaktivierte) Peronsen werden wieder aktiv.",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.candidates = [];
        for (const person of this.value) {
          this.candidates.push({ ...person, disabled: false, active: false });
        }
      }
    },
    distributeLast() {
      var remaining = this.groups.pop();
      var currentIndex = remaining.length;
      var groupIndex = 0;
      while (currentIndex > 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        var person = remaining[randomIndex];
        this.groups[groupIndex].push(person);
        currentIndex--;
        groupIndex = (groupIndex + 1) % this.groups.length;
      }
    },
    async divide(size) {
      await this.reset();
      this.groupSize = 0;
      for (let i = 0; i < size; i++) {
        this.groups.push([]);
      }
      var remaining = this.candidates.filter((el) => !el.disabled);
      var currentIndex = remaining.length;
      var groupIndex = 0;
      while (currentIndex > 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        var person = remaining[randomIndex];
        this.groups[groupIndex].push(person);
        remaining = remaining.filter((el) => el.id != person.id);
        currentIndex = currentIndex - 1;
        groupIndex = (groupIndex + 1) % size;
      }
    },
    async group(groupSize) {
      await this.reset();
      this.groupSize = groupSize;
      var remaining = this.candidates.filter((el) => !el.disabled);
      const size = Math.ceil(remaining.length / groupSize);
      for (let i = 0; i < size; i++) {
        this.groups.push([]);
      }
      var currentIndex = remaining.length;
      var groupIndex = 0;
      while (currentIndex > 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        var person = remaining[randomIndex];
        this.groups[groupIndex].push(person);
        if (this.groups[groupIndex].length == groupSize) {
          groupIndex++;
        }
        remaining = remaining.filter((el) => el.id != person.id);
        currentIndex--;
      }
    },
    choice() {
      for (const person of this.candidates.filter((el) => el.active)) {
        person.active = false;
        person.disabled = true;
      }
      var remaining = this.candidates.filter((el) => !el.disabled && !el.done);
      var currentIndex = remaining.length;
      if (currentIndex == 0) {
        return;
      }

      var randomIndex = Math.floor(Math.random() * currentIndex);
      var person = remaining[randomIndex];
      person.active = true;
    },
    async shuffle() {
      await this.reset();
      var remaining = this.candidates.filter((el) => !el.disabled);
      var currentIndex = remaining.length;
      while (currentIndex > 0) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        var person = remaining[randomIndex];
        this.shuffeled.push(person);
        remaining = remaining.filter((el) => el.id != person.id);
        currentIndex = currentIndex - 1;
      }
    },
    needsSort(group) {
      if (this.sorted) {
        return sortPeople(group);
      }
      return group;
    },
    copyGroups() {
      let result = "";
      let index = 1;
      for (const group of this.groups) {
        result += "Gruppe " + index + "\n";
        result += this.groupToText(group) + "\n";
        index++;
      }
      this.copyToClipboard(result);
    },
    copyShuffle() {
      this.copyToClipboard(this.groupToText(this.shuffeled));
    },
    groupToText(group) {
      let result = "";
      for (const person of this.needsSort(group)) {
        result += this.personName(person) + "\n";
      }
      return result;
    },
  },
  created() {
    this.prepare();
  },
};
</script>
<style scoped>
:deep .large .v-chip .v-avatar {
  height: 44px !important;
  min-width: 44px !important;
  width: 44px !important;
}

:deep .v-chip.active.v-chip--outlined.v-chip.v-chip {
  background: lightblue !important;
}

.flip-list-move {
  transition: transform 1s;
}
</style>
